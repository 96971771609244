<template>
  <div class="vx-row">
    <div class="vx-col w-full">
       <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :filename="filename"
          :pdf-quality="1"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="100%"

          ref="html2Pdf"
        >
          <section class="p-10" slot="pdf-content">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-1/3 mt-base">

                  </div>
                  <div class="vx-col w-1/3 mt-base text-center">
                    <div class="titre_etat_regl">
                      <h1>Etat Stock Article</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/3 mt-base text-right">
                    <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                    <h4>Officine: </h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <h4>Date {{DateDebut | moment("calendar", "July 10 2011") }}</h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- {{reglements_client}} -->
                  <table class="w-full" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th colspan="1" style="border-right-width: 0px;" >Etiquette</th>
                        <th colspan="1" style="border-right-width: 0px;">Marque</th>
                        <th colspan="1" style="border-right-width: 0px;">Taille</th>
                        <th colspan="1" style="border-right-width: 0px;">Couleur</th>
                        <th colspan="1" style="border-right-width: 0px;">Catégorie</th>
                        <th colspan="1" style="border-right-width: 0px;">PvTTC</th>
                        <th colspan="1" style="">Qte</th>
                      </tr>
                    </thead>
                    <!-- v-if="br.length >0" -->
                    <tbody>
                      <template v-for="(tr, indextr) in stock">
                        <tr :key="indextr">
                          <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Etiquette}}</td>
                          <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getMarqueName(tr.IdMarque)}}</td>
                          <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Taille[0].Hauteur}}/{{tr.Taille[0].Nez}}/{{tr.Taille[0].Longeur}}</td>
                          <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Couleur}}</td>
                          <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getCategorieName(tr.IdCateg)}}</td>
                          <td class="text-right" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">{{moneyFormatter((tr.PrixVenteTTC > 0 ? tr.PrixVenteTTC : tr.PrixVenteHT)*tr.stock)}}</td>
                          <td class="text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.stock}}</td>
                        </tr>
                      </template>
                    </tbody>

                    <tfoot>
                      <tr>
                        <th class="text-center" colspan="5" style="border-right-width: 0px;">TOTAL</th>
                        <th class="text-right" style="border-right-width: 0px;" colspan="1">{{totalPvTTc(stock)}}</th>
                        <th class="text-right" colspan="1">{{totalQteArticle(stock)}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
      <vx-card :refreshContentAction="true" @refresh="RefreshContent()">
        <div class="vx-row">
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date Etat</label>
            <flat-pickr v-model.lazy="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <vs-select label="Catégorie article" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model.lazy="categorie_article">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.Abreg_Categ" v-for="(item,index) in categories_article" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getStockArticleOnSpecifiquePeriode()">VALIDER</vs-button>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-file" @click="printStockToPdf()">IMPRESSION</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl">
                  <h1>Etat Stock Article</h1>
                </div>
              </div>
              <div class="vx-col w-1/3 mt-base text-right">
                <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                <h4>Officine: </h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Date {{DateDebut | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th colspan="1" style="border-right-width: 0px;" >Etiquette</th>
                    <th colspan="1" style="border-right-width: 0px;">Marque</th>
                    <th colspan="1" style="border-right-width: 0px;">Taille</th>
                    <th colspan="1" style="border-right-width: 0px;">Couleur</th>
                    <th colspan="1" style="border-right-width: 0px;">Catégorie</th>
                    <th colspan="1" style="border-right-width: 0px;">PvTTC</th>
                    <th colspan="1" style="">Qte</th>
                  </tr>
                </thead>
                <!-- v-if="br.length >0" -->
                <tbody>
                  <template v-for="(tr, indextr) in stock">
                    <tr :key="indextr">
                      <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Etiquette}}</td>
                      <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getMarqueName(tr.IdMarque)}}</td>
                      <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Taille[0].Hauteur}}/{{tr.Taille[0].Nez}}/{{tr.Taille[0].Longeur}}</td>
                      <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.Couleur}}</td>
                      <td colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getCategorieName(tr.IdCateg)}}</td>
                      <td class="text-right" colspan="1" style="border-bottom-width: 0px;border-right-width: 0px;">{{moneyFormatter((tr.PrixVenteTTC > 0 ? tr.PrixVenteTTC : tr.PrixVenteHT)*tr.stock)}}</td>
                      <td class="text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.stock}}</td>
                    </tr>
                  </template>
                </tbody>

                <tfoot>
                  <tr>
                    <th class="text-center" colspan="5" style="border-right-width: 0px;">TOTAL</th>
                    <th class="text-right" style="border-right-width: 0px;" colspan="1">{{totalPvTTc(stock)}}</th>
                    <th class="text-right" colspan="1">{{totalQteArticle(stock)}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      categorie_article: null,
      filename: 'stock'
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    stock () {
      return this.$store.state.etat.articles_en_stock
    },
    categories_article () {
      return this.$store.state.categorie_article.categorie_articles
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    }
  },
  methods: {
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getMarqueName (id) {
      return this.$store.getters['marque/getMarqueName'](id)
    },
    getCategorieName (id) {
      return this.$store.getters['categorie_article/getCategorieName'](id)
    },
    totalQteArticle (stocks) {
      return stocks.reduce((accumulator, stock) => {
        return accumulator + (+stock.stock)
      }, 0)
    },
    totalPvTTc (stocks) {
      const montant = stocks.reduce((accumulator, stock) => {
        return accumulator + ((+stock.PrixVenteTTC > 0 ? +stock.PrixVenteTTC : stock.PrixVenteHT) * stock.stock)
      }, 0)
      return this.moneyFormatter(montant)
    },
    printStockToPdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      setTimeout(() => {
        this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    getStockArticleOnSpecifiquePeriode () {
      const payload = {
        idOfficine: this.idOfficine,
        categorie_article: this.categorie_article
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('etat/getStockArticleOnSpecifiquePeriode', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })

    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    RefreshContent () {
      this.getOfficines()
      this.getCategorieArticles()
      this.DateDebut = null
      this.idOfficine = null
      this.categorie_article = null
      this.$refs.filter_card.removeRefreshAnimation(3000)
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 4px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #CFCFCF;
}
</style>
